import React from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "../styles/staticPages.js";
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function TermCondition(props) {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const settings = useSelector((state) => state.settingsdata.settings);
  const { ...rest } = props;
  return (
    <div style={{ margin: "-8px" }}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/header-back.jpg").default}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <br />
          <h2
            style={{
              textAlign: isRTL === "rtl" ? "right" : "left",
              position: "relative",
              marginTop: "30px",
              minHeight: "32px",
              color: "#383838",
              textDecoration: "none",
            }}
          >
            {t("term_condition")}
          </h2>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {t("term_condition_para1")}
          </div>
          <br />
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            <strong>{t("term_condition_heading1")}</strong>
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {t("term_condition_para2")}
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            <strong>{t("term_condition_heading2")}</strong>
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {t("term_condition_para3")}
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            <strong>{t("term_condition_heading3")}</strong>
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {t("term_condition_para4")}
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            <strong>{t("term_condition_heading4")}</strong>
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {t("term_condition_para5")}
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            <strong>{t("term_condition_heading5")}</strong>
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {t("term_condition_para6")}
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            <strong>{t("term_condition_heading6")}</strong>
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {t("term_condition_para7")}
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            <strong>{t("term_condition_heading7")}</strong>
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {t("term_condition_para8")}
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            <strong>{t("term_condition_heading8")}</strong>
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {t("term_condition_para9")}
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            <strong>{t("term_condition_heading9")}</strong>
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {t("term_condition_para10")}
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            <strong>{t("term_condition_heading10")}</strong>
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {t("term_condition_para11")}
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            <strong>{t("term_condition_heading11")}</strong>
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {t("term_condition_para12")}
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            <strong>{t("term_condition_heading12")}</strong>
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {t("term_condition_para13")}
          </div>
          <div
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {t("term_condition_para14")}
            {settings && settings.contact_email ? (
              <a href={"mailto:" + settings.contact_email}>
                <strong>{settings.contact_email}</strong>
              </a>
            ) : null}
          </div>
          <br />
          <br />
        </div>
      </div>
      <Footer />
    </div>
  );
}
