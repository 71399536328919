import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import HandsOnMobile from "../assets/img/handsonmobile.jpg";
import useStyles from "../styles/styles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import apple from "../assets/img/appstore.png";
import google from "../assets/img/playstore.png";

const DownloadApp = () => {
  const classes = useStyles();
  const settings = useSelector((state) => state.settingsdata.settings);
  const { t } = useTranslation();

  return (
    <Box className={classes.aboutUsContainer} style={{ marginBottom: -15 }}>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={12} md={5}>
          <img
            src={HandsOnMobile}
            alt="My Team"
            className={classes.largeImage}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <h2 variant="h2" fontWeight={700} className={classes.title}>
            Mobile Apps available on App Stores
          </h2>
          <div className={classes.aboutUsSubtitle}>
            Unlock the power of our cutting-edge mobile application and elevate
            your experience with just a few taps. Whether you're an Android or
            Apple enthusiast, you can access our app effortlessly on both major
            platforms
          </div>
          {settings && settings.AppleStoreLink ? (
            <a href={settings.AppleStoreLink}>
              <img src={apple} alt="Apple Store Link" />
            </a>
          ) : null}
          <span style={{ marginRight: "5px" }}></span>
          {settings && settings.PlayStoreLink ? (
            <a href={settings.PlayStoreLink}>
              <img src={google} alt="Playstore Link" />
            </a>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DownloadApp;
