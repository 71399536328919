import config from "../../../functions/config.json";
import base64 from "react-native-base64";

const createAccessKey = () => {
  let accessKey = base64.encode(
    `${config.purchase_code}-${config.firebaseProjectId}`
  );
  return accessKey;
};

export default createAccessKey();
